import { render, staticRenderFns } from "./SizeSelector.vue?vue&type=template&id=48407cbf&scoped=true&"
import script from "./SizeSelector.vue?vue&type=script&lang=js&"
export * from "./SizeSelector.vue?vue&type=script&lang=js&"
import style0 from "./SizeSelector.vue?vue&type=style&index=0&id=48407cbf&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48407cbf",
  null
  
)

export default component.exports