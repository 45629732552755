<template>
  <div class="fill-height pb-3 py-md-10">
    <Loading :loading="isLoading"></Loading>

    <v-carousel
      v-if="$vuetify.breakpoint.smAndDown"
      height="340px"
      hide-delimiter-background
    >
      <v-carousel-item v-for="(item, i) in productDetail.photos" :key="i">
        <v-img
          contain
          width="100%"
          max-height="100%"
          :src="item.image_url"
          @click="viewPhoto(item.image_url)"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
    <v-dialog v-model="photoDialog" width="100%">
      <v-img
        contain
        width="100%"
        max-height="100%"
        :src="dialogPhotoSrc"
        @click="photoDialog = !photoDialog"
      ></v-img>
    </v-dialog>

    <v-container>
      <!--            <Divider long="100%"></Divider>-->
      <v-layout
        :column="$vuetify.breakpoint.smAndDown"
        justify-space-between
        mb-7
        mt-3
        my-md-10
      >
        <v-flex
          v-if="$vuetify.breakpoint.mdAndUp"
          mb-5
          mb-md-0
          mr-0
          mr-md-4
          xs6
        >
          <v-skeleton-loader
              v-if="isLoading && Object.keys(productDetail).length === 0"
              type="image"
          ></v-skeleton-loader>
          <ProductCarousel
              v-else
              :images="productDetail.photos"
              @fullDialog="viewPhoto"
          ></ProductCarousel>
        </v-flex>
        <v-flex px-3 xs5>
          <v-layout
            :column="$vuetify.breakpoint.mdAndUp"
            justify-space-between
            mb-5
          >
            <div class="darkblue-color txt-m-large mb-0 mb-md-5">
              {{ productDetail.name }}
            </div>
            <div class="txt-bkk-color txt-m-large text-no-wrap">
              {{ productDetail.currency }}
              {{ priceEach }}
            </div>
          </v-layout>
          <div
            v-if="$vuetify.breakpoint.smAndDown"
            class="txt-detail grey--text mb-7"
          >
            {{ productDetail.description }}
          </div>
          <SizeSelector
              v-if="productDetail.sku_size_stock_list && productDetail.sku_size_stock_list.length > 0"
              class="mb-7"
              :all-sizes="productDetail.sku_size_stock_list"
              @onSelectSize="sizeSelected = $event"
              @onStockUpdate="onStockUpdate"
          ></SizeSelector>
          <div v-else class="grey--text">
            Size not available
          </div>
          <QuantitySelector
            :quantity-exceed="quantity >= stockBalance"
            :value="quantity"
            class="mb-7"
            @decrease="quantity--"
            @increase="quantity++"
            @setAtLeast="quantity = 1"
            @setInput="quantity = parseInt($event)"
          ></QuantitySelector>
          <Button
            :block="$vuetify.breakpoint.smAndDown"
            :large="true"
            class="mb-10"
            label="ADD TO BAG"
            type="dark"
            :disabled="quantity === 0"
            @onClick="addToBag"
          ></Button>
          <div v-if="$vuetify.breakpoint.mdAndUp">
            <!--                        <div class="darkblue-color txt-bigger mb-3">Top507 Shirt stripe</div>-->
            <div class="txt-detail grey-color">
              {{ productDetail.description }}
            </div>
          </div>
        </v-flex>
      </v-layout>

      <div class="txt-head font-weight-light">RELATED PRODUCT</div>
      <Divider long="100%"></Divider>
      <v-layout v-if="relatedProduct.length > 0">
        <v-flex v-for="(item, i) in relatedProduct" :key="i" xs4>
          <ProductSlot
            :class="[(i + 1) % 3 == 0 ? 'pr-0' : 'mr-n5']"
            :img="item.img"
            :name="item.name"
            :price="item.price"
            @onAddToCart="addToCart(item.id)"
            @onSeeDetail="getDetail(item.id)"
          ></ProductSlot>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Divider from '../components/Divider'
import ProductCarousel from '../components/ProductCarousel'
import SizeSelector from '../components/input/SizeSelector'
import QuantitySelector from '../components/input/QuantitySelector'
import Button from '../components/input/Button'
import ProductSlot from '../components/ProductSlot'
import { mapState } from 'vuex'
import Loading from '../components/Loading'
import { formatNumber } from "@/plugins/global-funcs";

export default {
  name: 'ProductDetail',
  components: {
    Divider,
    ProductCarousel,
    SizeSelector,
    QuantitySelector,
    Button,
    ProductSlot,
    Loading
  },
  data() {
    return {
      quantity: 1,
      stockBalance: 0,
      sizeSelected: undefined,
      relatedProduct: [],
      isLoading: false,
      photoDialog: false,
      dialogPhotoSrc: null,
      formatNumber: formatNumber
    }
  },
  async created() {
    this.isLoading = true
    this.$store.commit('Products/clearProductDetail')
    await this.$store.dispatch(
      'Products/onGetProductById',
      this.$route.params.id
    )
    // set default selected
    if (this.productDetail.sku_size_stock_list && this.productDetail.sku_size_stock_list.length > 0) {
      this.sizeSelected = this.productDetail.sku_size_stock_list[0].id
      this.stockBalance = this.productDetail.sku_size_stock_list[0].stock
    }
    this.isLoading = false
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      productDetail: (state) => state.Products.product,
      accessToken: state => state.Auth.userDetail.access_token
    }),
    priceEach: function() {
      if (this.productDetail.sku_size_stock_list && this.productDetail.sku_size_stock_list.length > 0) {
        let a = this.productDetail.sku_size_stock_list.filter(x => x.id === this.sizeSelected)
        if (a.length > 0) return formatNumber(a[0].price, 2)
      }
      return '-'
    }
  },
  methods: {
    async addToBag() {
      if (!this.accessToken) {
        this.$store.commit('Auth/loginDialogHandle', true)
        return
      }
      let item = this.inCart.filter(x => {
        if (x.product_stock_id === this.sizeSelected) {
          return x
        }
      })
      if (item.length > 0) {
        if ((item[0].quantity + this.quantity) > this.stockBalance) {
          this.$swal.fire({
            ...this.$swalError,
            text: 'Not enough products'
          })
          return
        }
      }
      if (!this.sizeSelected) {
        this.$swal.fire({
          ...this.$swalError,
          text: 'Please pick a size'
        })
        return
      }
      if (!this.accessToken) {
        this.$swal.fire({
          ...this.$swalError,
          text: 'Please login first'
        })
        return
      }

      let product = {
        access_token: this.accessToken,
        product_stock_id: this.sizeSelected,
        quantity: this.quantity
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/add', product)
      this.isLoading = false
      this.$store.dispatch('Cart/popUpDialog')
    },
    viewPhoto (src) {
      this.dialogPhotoSrc = src
      this.photoDialog = !this.photoDialog
    },
    onStockUpdate (stock_balance) {
      // when select size, stock balance will update
      if (this.quantity > stock_balance) this.quantity = stock_balance
      this.stockBalance = stock_balance
    },
    getDetail (id) {
      console.log(id)
    },
    addToCart (id) {
      console.log('add')
    }
  }
}
</script>

<style scoped></style>
