<template>
  <div class="flex-center-col">
      <div class="mr-5">Size</div>
      <div
          v-for="(item, i) in allSizes"
          :key="i"
          class="py-2 mr-2 size-list"
          :class="{ 'size-list-active': value === item.id }"
          @click="eventSelectSize(item.id, item.stock)"
      >
        {{ item.size }}
      </div>
      <div v-if="stock" class="txt-detail grey--text">Stock: {{ stock }}</div>
  </div>
</template>

<script>
export default {
  name: 'SizeSelector',
  props: {
    allSizes: {
      default: []
    },
    defaultSize: {
      default: null
    }
  },
  data () {
    return {
      value: this.defaultSize ? this.defaultSize : this.allSizes[0].id,
      stock: this.allSizes[0].stock ? this.allSizes[0].stock : 0
    }
  },
  watch: {
    allSizes: function () {
      this.value = this.defaultSize ? this.defaultSize : this.allSizes[0].id
      this.stock = this.allSizes[0].stock ? this.allSizes[0].stock : 0
    }
  },
  methods: {
    eventSelectSize(id, stock) {
      this.value = id
      this.stock = stock
      this.$emit('onSelectSize', id)
      this.$emit('onStockUpdate', stock)
    }
  }
}
</script>

<style scoped lang="scss">
.size-list {
  color: #fff;
  width: 40px;
  text-align: center;
  background: #dbdbdb;
  cursor: pointer;
  &.size-list-active {
    background: var(--bkk-darkblue);
  }
}
</style>
