<template>
  <div>
    <v-btn
      v-if="type === 'bkk'"
      outlined
      :large="large"
      color="var(--bkk-theme)"
      :style="{ width: width, 'border-radius': radius }"
      :block="block"
      :disabled="disabled"
      @click="eventClick"
    >
      <v-icon small class="mr-2" v-if="prependIcon">{{ prependIcon }}</v-icon>
      <v-img
        :width="resSize"
        :max-height="resSize"
        contain
        class="mr-2"
        v-if="prependResIcon"
        :src="prependResIcon"
      ></v-img>
      <span style="color: #000">{{ label }}</span>
      <v-icon small class="ml-2" v-if="appendIcon">{{ appendIcon }}</v-icon>
      <v-img
        :width="resSize"
        :max-height="resSize"
        contain
        class="mr-2"
        v-if="appendResIcon"
        :src="appendResIcon"
      ></v-img>
    </v-btn>
    <v-btn
      v-if="type === 'dark'"
      elevation="0"
      :large="large"
      color="var(--bkk-darkblue)"
      :style="{ width: width, 'border-radius': radius }"
      :block="block"
      :disabled="disabled"
      @click="eventClick"
    >
      <v-icon small class="mr-2" v-if="prependIcon">{{ prependIcon }}</v-icon>
      <v-img
        :width="resSize"
        :max-height="resSize"
        contain
        class="mr-2"
        v-if="prependResIcon"
        :src="prependResIcon"
      ></v-img>
      <span style="color: #fff">{{ label }}</span>
      <v-icon small class="ml-2" v-if="appendIcon">{{ appendIcon }}</v-icon>
      <v-img
        :width="resSize"
        :max-height="resSize"
        contain
        class="mr-2"
        v-if="appendResIcon"
        :src="appendResIcon"
      ></v-img>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: {
      default: ''
    },
    type: {
      default: 'bkk'
    },
    large: {
      default: false
    },
    block: {
      default: false
    },
    width: {
      default: 'auto'
    },
    radius: {
      default: '4px'
    },
    disabled: {
      default: false
    },
    prependIcon: {
      default: undefined
    },
    prependResIcon: {
      default: undefined
    },
    appendIcon: {
      default: undefined
    },
    appendResIcon: {
      default: undefined
    },
    resSize: {
      default: '25px'
    }
  },
  methods: {
    eventClick() {
      this.$emit('onClick')
    }
  }
}
</script>

<style scoped></style>
